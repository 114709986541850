import './App.css';
import { ThemeProvider } from 'styled-components';
import { connectorTheme as theme } from 'akeneo-design-system';
import Header from './component/Header';
import SettingsPage from './page/SettingsPage';

function App() {

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header></Header>
        <SettingsPage></SettingsPage>
      </ThemeProvider>
    </>
  );
}

export default App;
