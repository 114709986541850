import logo from "../logo-my-custom-start-app.svg";

function Header() {
    return (
        <section>
            <img className='app-logo' src={logo} alt="akeneo custom app logo" />
            <div className='breadcrumb'>
            </div>
        </section>
    );
}

export default Header;
