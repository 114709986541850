import React, { useState, useEffect } from 'react';
import {
  SectionTitle,
  TextInput,
  Button,
} from 'akeneo-design-system';

type Settings = {
  client_id: string;
  client_secret: string;
};

// TODO: manage environments
const API_URL = '/settings/bff';

const SettingsPage: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {

    const fetchSettings = async () => {
        const response = await fetch(API_URL);
        const settings = await response.json();

        setSettings(settings);
    };

    fetchSettings()

  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    const newSettings = {
      client_id: clientId,
      client_secret: clientSecret,
    };

    fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newSettings),
    })
      .then((response) => response.json())
      .then(() => {
        setSettings(newSettings);
        setIsEditing(false);
      })
      .catch((error) => console.error('Error saving settings:', error));
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const maskSecret = (secret: string) => {
    return secret ? `${'.'.repeat(secret.length - 2)}${secret.slice(-2)}` : '';
  };

  return (
    <div>
      <SectionTitle>Settings</SectionTitle>

      {isEditing || !settings ? (
        <>
          <TextInput
            aria-labelledby="Client ID"
            placeholder="Enter your Client ID"
            type="password"
            value={clientId}
            onChange={(newValue: string) => setClientId(newValue)}
          />

          <TextInput
            aria-labelledby="Client Secret"
            placeholder="Enter your Client Secret"
            type="password"
            value={clientSecret}
            onChange={(newValue: string) => setClientSecret(newValue)}
          />

          <Button onClick={handleSaveClick}>Save</Button>
          <Button onClick={handleCancelClick} ghost>
            Cancel
          </Button>
        </>
      ) : (
        <>
          <TextInput
            aria-labelledby="Client ID"
            value={maskSecret(settings.client_id)}
            readOnly
          />

          <TextInput
            aria-labelledby="Client Secret"
            value={maskSecret(settings.client_secret)}
            readOnly
          />

          <Button onClick={handleEditClick}>Edit</Button>
        </>
      )}
    </div>
  );
};

export default SettingsPage;
